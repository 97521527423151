import React from "react";
// import "antd/dist/antd.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./static/css/fontawesome.min.css";
import "./static/css/codebase.css";
import "./static/css/main.css";
import "./static/css/main.scss";

import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.js";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  HomeView,
  LoginView,
  RegisterView,
  AppRun,
  Proxy,
  NotifySystem,
  LogGetCookie,
  SmsView,
  OrderSmsView,
  AdminManagerUserView,
  ListEmailView,
  AppTest,
  AppView,
  ScriptContentView,
  TableTest,
  TaskActionView,
  TaskDetailActionView,
  TaskActionFacebook,
  TaskActionInstagram,
  TaskActionKakaotalk,
  ListActionView,
  AdminActionPlatformView,
  AdminScriptContentView,
  ScreenAppView,
  AdminListActionView,
  AdminJobQueueView,
  ActionDataLogView,
  ShareDataView,
  GoogleApiPage,
  AdminSystemDataPage,
  CopyPostPage
} from "./view";

import { AccountMetaData } from "./lib/account";
import { fetchData } from "./lib/helpers";
import { message, Spin, notification } from "antd";
import io from "socket.io-client";

//hiếu import
import CreateAction from "./view/Action/CreateAction/CreateAction";
import ManageContent from "./view/Action/ManageContent/ManageContent";
import { Header, Sidebar, PopupMenu } from "./component";
import { connect } from "react-redux";
import { ActionAccess } from "./redux/actions";

require("moment/locale/vi");

class AppComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userSystem: null,
      loading: false,
      totalNoti: 0,
      dataNotify: [],
      userToken: AccountMetaData.getToken(),
    };
    this.setInfo = this.setInfo.bind(this);
    this.updateUserSystem = this.updateUserSystem.bind(this);
  }
  componentWillMount = () => {
    window.socket = io(process.env.REACT_APP_API, {
      transports: ["websocket"],
      reconnectionDelay: 2000,
    });
    if (AccountMetaData.getToken()) {
      this.getInfoUser();
    }
  };
  componentDidMount = () => {
    // window.socket.emit('hello')
  };
  getInfoUser = async () => {
    try {
      this.setState({ loading: true });
      let params = new URLSearchParams(window.location.search);

      let resutl = await fetchData({
        url: `api/v1/user/detail?${params.get("fbase_ref") ? "fbase_ref=" + params.get("fbase_ref") : ""
          }`,
        method: "get",
      });
      this.setState(
        {
          loading: false,
          userSystem: resutl["data"],
          setting: resutl["setting"],
        },
        () => {
          this.getNotify();
          this.getActionPlatform();
        }
      );
    } catch (err) {
      notification.error({
        description: err.message || "Không thể đăng nhập, vui lòng thử lại sau!",
        message: "Thông báo"
      })
      setTimeout(() => {
        AccountMetaData.logOut();
        this.setState({ loading: false, userSystem: null });
      }, 3000)
    }
  };
  getActionPlatform = async () => {
    try {
      if (this.props.getActionPlatform) {
        this.props.getActionPlatform();
      }
    } catch (err) {
      console.log(err);
    }
  }
  getNotify = async () => {
    try {
      let resutl = await fetchData({
        url: "api/v1/notifications?limit=5",
        method: "get",
      });
      this.setState(
        {
          dataNotify: resutl,
        },
        () => {
          if (this.get_realtime_notify) clearInterval(this.get_realtime_notify);
          this.get_realtime_notify = setInterval(() => {
            this.getNotify();
          }, 10000);
        }
      );
    } catch (err) { }
  };
  setInfo = async (user) => {
    if (user) {
      this.getInfoUser();
    } else {
      this.setState({ userSystem: null });
    }
  };
  updateUserSystem = (user) => {
    this.setState({ userSystem: user });
  };
  logOut = () => {
    AccountMetaData.logOut();
    window.location.href = "/login";
    this.setState({ userSystem: null });
  };
  notification = (options) => {
    let { type, message, description, key, duration } = options;
    notification[type]({
      message: message,
      description: description,
      key: key,
      duration: duration,
    });
  };
  message = (options) => {
    let { type, key, content, duration } = options;
    message[type]({
      content: content,
      key: key,
      duration: duration,
    });
  };
  getNewToken = () => {
    this.setState({
      userToken: AccountMetaData.getToken(),
    });
  };
  updateUserSystem = (data) => {
    this.setState({
      userSystem: data,
    });
  };
  sleep = (ms) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(ms);
      }, ms);
    });
  };
  copyText = (text, message) => {
    let el = document.querySelector("#input-copy");
    el.textContent = text;
    el.select();
    document.execCommand("copy");
    this.notification({
      message: message || "Copy text",
      description: "Thao tác thành công!",
      type: "success",
    });
  };
  handleChecked = (e) => {
    try {
      let table = e.target.closest("table");
      let items = table.querySelectorAll('[name="check_item"]');
      let num_checked = 0;
      items.forEach((item) => {
        if (item.checked) num_checked++;
      });
      if (e.target.name == "checkall") {
        if (items.length == num_checked) {
          items.forEach((item) => {
            item.checked = false;
          });
          num_checked = 0;
        } else {
          items.forEach((item) => {
            item.checked = true;
          });
          num_checked = items.length;
        }
      }
      if (items.length == num_checked) {
        table.querySelector('[name="checkall"]').checked = true;
      } else {
        table.querySelector('[name="checkall"]').checked = false;
      }
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    if (this.state.loading) {
      return <div id="page-loader" className="bg-gd-sea show"></div>;
    }
    let props_m = {
      sleep: this.sleep,
      copyText: this.copyText,
      handleChecked: this.handleChecked,
      setting: this.state.setting,
      fetchData: fetchData,
      userSystem: this.state.userSystem,
      logOut: this.logOut,
      notification: this.notification,
      message: this.message,
      userToken: this.state.userToken,
      getNewToken: this.getNewToken,
      dataNotify: this.state.dataNotify,
      getNotify: this.getNotify,
      updateUserSystem: this.updateUserSystem,
    };
    if (this.state.userSystem) {
      return (
        <div>
          <div id="page-container"
            className="sidebar-mini sidebar-inverse enable-page-overlay side-scroll page-header-fixed page-header-inverse main-content-boxed side-trans-enabled sidebar-o page-header-scroll"
          >
            <textarea
              id="input-copy"
              style={{
                opacity: 0,
                position: "absolute",
                zIndex: 0,
                width: "1px",
                height: "1px",
                top: "-100px",
              }}
              onKeyPress={(e) => {
                e.preventDefault();
                return false;
              }}
              readOnly
            />

            <Router>
              <Header {...props_m} />
              <Sidebar {...props_m} />
              <main id="main-container">
                <div className="content">
                  <Switch>
                    <Route
                      path="/"
                      exact
                      render={(props) => (
                        <HomeView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/app_run"
                      render={(props) => (
                        <AppView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/app_run/action"
                      render={(props) => (
                        <AppRun
                          {...props}
                          {...props_m}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/app_run/script"
                      render={(props) => (
                        <ScriptContentView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/proxy"
                      render={(props) => (
                        <Proxy
                          {...props}
                          {...props_m}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/noti_system"
                      render={(props) => (
                        <NotifySystem
                          {...props}
                          {...props_m}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/log_get_cookie"
                      render={(props) => (
                        <LogGetCookie
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/sms"
                      render={(props) => <SmsView {...props} {...props_m} />}
                    />

                    <Route
                      exact
                      path="/sms/:sms_id"
                      render={(props) => <OrderSmsView {...props} {...props_m} />}
                    />

                    <Route
                      exact
                      path="/admin/user"
                      render={(props) => (
                        <AdminManagerUserView {...props} {...props_m} />
                      )}
                    />

                    <Route
                      exact
                      path="/email"
                      render={(props) => <ListEmailView {...props} {...props_m} />}
                    />

                    <Route
                      exact
                      path="/test"
                      render={(props) => <AppTest {...props} {...props_m} />}
                    />

                    {/* <Route
                      path="/create-action-facebook"
                      exact
                      render={(props) => {
                        return (
                          <TaskActionFacebook {...props} {...props_m} />
                        );
                      }}
                    ></Route>

                    <Route
                      path="/create-action-instagram"
                      exact
                      render={(props) => {
                        return (
                          <TaskActionInstagram
                            {...props}
                            {...props_m}
                          />
                        );
                      }}
                    ></Route>

                    <Route
                      path="/create-action-kakaotalk"
                      exact
                      render={(props) => {
                        return (
                          <TaskActionKakaotalk
                            {...props}
                            {...props_m}
                          />
                        );
                      }}
                    ></Route> */}

                    <Route
                      path="/create-action-:platform"
                      exact
                      render={(props) => {
                        return (
                          <TaskActionView {...props} {...props_m} />
                        );
                      }}
                    ></Route>

                    <Route
                      path="/manage-content"
                      exact
                      render={(props) => {
                        return <ManageContent {...props} {...props_m} />;
                      }}
                    ></Route>

                    <Route
                      path="/table-test"
                      exact
                      render={(props) => {
                        return <TableTest {...props} {...props_m} />;
                      }}
                    ></Route>

                    <Route
                      exact
                      path="/admin/app_run"
                      render={(props) => (
                        <AppView
                          {...props}
                          {...props_m}
                          roleTypeAdmin={true}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/task_action"
                      render={(props) => (
                        <TaskActionView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/task_action/:id"
                      render={(props) => (
                        <TaskDetailActionView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/actions"
                      render={(props) => (
                        <ListActionView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/admin/action_platform"
                      render={(props) => (
                        <AdminActionPlatformView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/admin/script"
                      render={(props) => (
                        <AdminScriptContentView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/app/screen"
                      render={(props) => (
                        <ScreenAppView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/admin/actions"
                      render={(props) => (
                        <AdminListActionView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/admin/jobs"
                      render={(props) => (
                        <AdminJobQueueView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/admin/system_data"
                      render={(props) => (
                        <AdminSystemDataPage
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/action/data"
                      render={(props) => (
                        <ActionDataLogView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/share_data"
                      render={(props) => (
                        <ShareDataView
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/googleapi"
                      render={(props) => (
                        <GoogleApiPage
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/copy_post"
                      render={(props) => (
                        <CopyPostPage
                          {...props}
                          {...props_m}
                        />
                      )}
                    />

                  </Switch>
                </div>
              </main>
            </Router>
          </div>
        </div>
      );
    }
    return (
      <div>
        <Router>
          <Switch>
            <Route
              exact
              path="/register"
              render={(props) => (
                <RegisterView {...props} notification={this.notification} />
              )}
            />
            <Route
              path="/"
              render={(props) => (
                <LoginView
                  {...props}
                  setInfo={this.setInfo}
                  notification={this.notification}
                />
              )}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapDispathToProps = (dispatch) => ({
  getActionPlatform: (value) => dispatch(ActionAccess.getActionPlatform(value))
})

export default connect(null, mapDispathToProps)(AppComponent);
