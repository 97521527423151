export const API_URL = {
    host: new URL(process.env.REACT_APP_API || window.location.origin).origin,
    appProfile: {
        list: "api/v2/app/profile/list",
        index: "api/v2/app/profile",
        listAll: "api/v2/app/profile/all",
        lastScreen: "api/v2/app/profile/last_screen",
        screenAlbum: "api/v2/app/profile/screen_album",
    },
    taskAction: {
        index: "api/v1/task_action",
        status: "api/v1/task_action/status"
    },
    aciton: {
        list: "api/v1/action/list",
        create: "api/v1/action/create",
        delete: "api/v1/action/delete"
    },
    scriptContent: {
        index: "api/v1/script_content"
    },
    account: {
        updateMany: "api/v2/user_fb/update_many",
        folder: "api/v1/folder/list",
        update: "api/v1/user_fb"
    },
    admin: {
        appProfile: {
            index: "api/v1/admin/profile"
        },
        aciton: {
            index: "api/v1/admin/action"
        },
        platform: {
            index: "api/v1/admin/platform",
            action: {
                index: "api/v1/admin/action_platform"
            }
        },
        scriptContent: {
            index: "api/v1/admin/script_content"
        },
        setting: {
            index: "api/v1/admin/setting"
        },
        systemData: {
            index: "api/v1/admin/system/data"
        },
    },
    upload: "api/v1/uploads",
    postContent: {
        folder: "api/v1/post/folder/list"
    },
    platform: {
        action: {
            index: "api/v1/action_platform"
        }
    },
    job: "api/job-kue",
    userDataLog: {
        index: "api/v1/user/data",
        saveMany: "api/v1/user/data/save_many"
    },
    shareData: {
        index: "api/v1/share_data",
        changeExpired: "api/v1/share_data/expired",
        chechkExpired: "api/v1/share_data/check_expired"
    },
    googleapi: {
        login: "api/v1/googleapi/login",
        index: "api/v1/googleapi",
        driverFiles: "api/v1/googleapi/drive/files"
    },
    CopyPost: {
        index: "api/v1/copy_post"
    },
}