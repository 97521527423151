export const ConfigListAction = [
    //facebook
    {
        key: "farm_nick_facebook",
        value: "Nuôi nick facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Tùy chọn nuôi",
                name: "farmOption",
                extra: "( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )",
                type: "checkbox",
                values: [
                    {
                        value: "likeWhileView",
                        name: "Like khi lướt Feed (Watch)",
                    },
                    {
                        value: "commetWhileView",
                        name: "Comment khi lướt Feed (Watch)",
                    },
                    {
                        value: "viewNotification",
                        name: "Xem thông báo",
                    },
                    {
                        value: "viewAndLikeStory",
                        name: "Xem và like story",
                    },
                    {
                        value: "addFriendWhileView",
                        name: "Kết bạn gợi ý",
                    },
                    {
                        value: "viewFacebookWatch",
                        name: "Lướt xem Facebook Watch",
                    }
                ]
            },
            {
                label: "Nội dung comment",
                name: "commentContent",
                extra: "(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            }
        ]
    },
    {
        key: "login_nick_facebook",
        value: "Login & backup nick facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            }
        ]
    },
    {
        key: "join_group_facebook",
        value: "Tham gia nhóm facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Nội dung câu trả lời",
                name: "answerContent",
                type: "textarea",
                defaultValue: "okey"
            },
            {
                label: "Danh sách link nhóm",
                name: "linkGroup",
                type: "textarea",
                placeholder: "Mỗi link group tương ứng một dòng",
                required: true
            }
        ]
    },
    {
        key: "farm_group_facebook",
        value: "Lướt xem nhóm facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Tùy chọn nuôi",
                name: "farmOption",
                type: "checkbox",
                extra: "( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )",
                values: [
                    {
                        name: "Like khi lướt Feed Group",
                        value: "likeWhileView"
                    },
                    {
                        name: "Comment khi lướt Feed Group",
                        value: "commetWhileView"
                    },
                    {
                        name: "Xem thông báo",
                        value: "viewNotification"
                    }
                ]
            },
            {
                label: "Nội dung share",
                name: "shareContent",
                extra: "(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            },
            {
                label: "Nội dung comment",
                name: "commentContent",
                extra: "(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            }
        ]
    },
    {
        key: "interact_group_post_facebook",
        value: "Tương tác post nhóm facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Link post Facebook",
                name: "linkPost",
                required: true,
                extra: "(* Lưu ý: bài post phải được đặt chế độ công khai *)",
                tooltip: "Mỗi link bài viết tương ứng 1 dòng",
                type: "textarea",
                placeholder: "Format mẫu: https://www.facebook.com/groups/id_group/posts/id_post"
            },
            {
                label: "Tùy chọn tương tác",
                name: "farmOption",
                type: "checkbox",
                extra: "( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )",
                values: [
                    {
                        name: "Like khi lướt Feed Group",
                        value: "likeWhileView"
                    },
                    {
                        name: "Comment khi lướt Feed Group",
                        value: "commetWhileView"
                    },
                    {
                        name: "Xem thông báo",
                        value: "viewNotification"
                    }
                ]
            },
            {
                label: "Nội dung share",
                name: "shareContent",
                extra: "(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            },
            {
                label: "Nội dung comment",
                name: "commentContent",
                extra: "(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            }
        ]
    },
    {
        key: "interact_regular_post_facebook",
        value: "Tương tác post facebook trên newsfeed",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Link post Facebook",
                name: "linkPost",
                required: true,
                extra: "(* Lưu ý: bài post phải được đặt chế độ công khai *)",
                tooltip: "Mỗi link bài viết tương ứng 1 dòng",
                type: "textarea",
                placeholder: ""
            },
            {
                label: "Tùy chọn tương tác",
                name: "farmOption",
                type: "checkbox",
                extra: "( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )",
                values: [
                    {
                        name: "Like khi lướt Feed Group",
                        value: "likeWhileView"
                    },
                    {
                        name: "Comment khi lướt Feed Group",
                        value: "commetWhileView"
                    },
                    {
                        name: "Xem bình luận",
                        value: "viewNotification"
                    },
                    {
                        name: "Share bài viết",
                        value: "shareWhileView"
                    }
                ]
            },
            {
                label: "Nội dung share",
                name: "shareContent",
                extra: "(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            },
            {
                label: "Nội dung comment",
                name: "commentContent",
                extra: "(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            }
        ]
    },
    {
        key: "interact_regular_video_facebook",
        value: "Tương tác video facebook trên newsfeed",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Link video post Facebook",
                name: "linkPost",
                required: true,
                extra: "(* Lưu ý: bài post phải được đặt chế độ công khai *)",
                tooltip: "Mỗi link bài viết tương ứng 1 dòng",
                type: "textarea",
                placeholder: "Format mẫu: https://www.facebook.com/page_name/videos/id_post/"
            },
            {
                label: "Tùy chọn tương tác",
                name: "farmOption",
                type: "checkbox",
                extra: "( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )",
                values: [
                    {
                        name: "Like khi lướt Feed Group",
                        value: "likeWhileView"
                    },
                    {
                        name: "Comment khi lướt Feed Group",
                        value: "commetWhileView"
                    },
                    {
                        name: "Xem bình luận",
                        value: "viewNotification"
                    },
                    {
                        name: "Share bài viết",
                        value: "shareWhileView"
                    }
                ]
            },
            {
                label: "Nội dung share",
                name: "shareContent",
                extra: "(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            },
            {
                label: "Nội dung comment",
                name: "commentContent",
                extra: "(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            }
        ]
    },
    {
        key: "add_friend_facebook",
        value: "Kết bạn theo danh sách UID facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Tùy chọn kết bạn",
                name: "farmOptionRadio",
                type: "radio",
                required: true,
                defaultValue: "sameUid",
                values: [
                    {
                        name: "Trùng UID",
                        value: "sameUid",
                        tooltip: "Các nick sẽ kết bạn với cùng một tệp UID"
                    },
                    {
                        name: "Không trùng UID",
                        value: "separateUid",
                        tooltip: "Mỗi nick sẽ kết bạn với 1 tập UID khác nhau"
                    }
                ]
            },
            {
                label: "Giới hạn kết bạn / 1 nick",
                name: "maxNumAddFriend",
                type: "inputNumber",
                min: 1,
                required: true,
                defaultValue: 1
            },
            {
                label: "Tùy chọn nuôi",
                name: "farmOption",
                type: "checkbox",
                tooltip: "Random lướt xem và like bài viết trên tường UID khi kết bạn (chỉ xem khoảng 30-40% lượng UID, mỗi quá trình kéo dài khoảng 1 phút)",
                values: [
                    {
                        name: "Like post trên tường khi kết bạn",
                        value: "likeWhileView"
                    }
                ]
            },
            {
                label: "Danh sách UID",
                name: "uidList",
                extra: "* Mỗi UID 1 dòng *",
                type: "textarea",
                required: true
            }
        ]
    },
    {
        key: "post_wall_facebook",
        value: "Đăng bài lên tường facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                type: "selectPostContent"
            }
        ]
    },
    {
        key: "post_group_facebook",
        value: "Đăng bài vào nhóm facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick đăng",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Link nhóm đăng bài",
                name: "linkGroup",
                type: "textarea",
                required: true,
                placeholder: "Mỗi link group tương ứng 1 dòng.."
            },
            {
                type: "selectPostContent"
            }
        ]
    },
    {
        key: "seeding_uid_facebook",
        value: "Seeding theo danh sách UID facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Tùy chọn tương tác",
                name: "farmOption",
                extra: "( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )",
                type: "checkbox",
                values: [
                    {
                        value: "likeWhileView",
                        name: "Like post",
                    },
                    {
                        value: "commetWhileView",
                        name: "Comment post",
                    }
                ]
            },
            {
                label: "Số post tương tác",
                name: "postToInteractnum",
                type: "inputNumber",
                tooltip: "Số lượng tối đa bài viết tương tác/1 UID, hệ thống sẽ tự động random để tránh trùng lặp",
                min: 1
            },
            {
                label: "Nội dung comment",
                name: "commentContent",
                extra: "(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            },
            {
                label: "Danh sách UID",
                name: "uidList",
                type: "textarea",
                required: true,
                extra: "* Mỗi UID 1 dòng *"
            }
        ]
    },
    {
        key: "view_livestream_facebook",
        value: "Livestream facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Link livestream",
                name: "linkPost",
                required: true,
                type: "inputText",
                placeholder: "Format mẫu: https://www.facebook.com/[page_id]/videos/[video_id]"
            },
            {
                label: "Thời gian xem trước tương tác (s)",
                name: "timeBeforeInteractive",
                type: "inputNumber",
                defaultValue: 0,
                min: 0
            },
            {
                name: "farmOption",
                label: "Tùy chọn tương tác",
                type: "checkbox",
                colSpan: 8,
                values: [
                    {
                        name: "Like post",
                        value: "likePost",
                    },
                    {
                        name: "Comment post",
                        value: "commentPost",
                        childrenActive: {
                            name: "commentPostNum",
                            type: "inputNumber",
                            placeholder: "số lượng",
                            required: true
                        }
                    },
                    {
                        name: "Share bài viết trong nhóm",
                        value: "shareGroup",
                        childrenActive: {
                            name: "shareGroupNum",
                            type: "inputNumber",
                            placeholder: "số nhóm",
                            required: true
                        }
                    },
                    {
                        name: "Xem bình luận",
                        value: "viewComment"
                    },
                    {
                        name: "Share bài viết về tường",
                        value: "sharePage"
                    }
                ]
            },
            {
                label: "Nội dung share",
                name: "shareContent",
                type: "textarea",
                extra: "(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)",
                defaultValue: "haha"
            },
            {
                label: "Nội dung comment",
                name: "commentContent",
                type: "textarea",
                extra: "(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)",
                defaultValue: "haha"
            },
            {
                name: "timeAfterInteractive",
                label: "Thời gian xem sau khi tương tác (s)",
                type: "inputNumber",
                defaultValue: 0,
                min: 0
            }
        ]
    },
    {
        key: "like_page_facebook",
        value: "Like page facebook",
        platform: "facebook",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Loại page",
                name: "pageType",
                required: true,
                type: "select",
                values: [
                    {
                        name: "Fanpage",
                        value: "fanpage"
                    },
                    {
                        name: "Page cá nhân",
                        value: "page"
                    }
                ]
            },
            {
                label: "Id pages",
                name: "linkPages",
                type: "textarea",
                extra: "(* Mỗi page tương ứng một dòng)",
                required: true
            }
        ]
    },

    //instagram
    {
        key: "farm_nick_instagram",
        value: "Nuôi nick instagram",
        platform: "instagram",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                label: "Tùy chọn nuôi",
                name: "farmOption",
                extra: "( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )",
                type: "checkbox",
                values: [
                    {
                        value: "likeWhileView",
                        name: "Like khi lướt Feed",
                    },
                    {
                        value: "commetWhileView",
                        name: "Comment khi lướt Feed",
                    },
                    {
                        value: "viewAndLikeStory",
                        name: "Xem story",
                    },
                    {
                        value: "addFriendWhileView",
                        name: "Follow bạn gợi ý",
                    }
                ]
            },
            {
                label: "Nội dung share",
                name: "shareContent",
                extra: "(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            },
            {
                label: "Nội dung comment",
                name: "commentContent",
                extra: "(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)",
                type: "textarea",
                defaultValue: "haha"
            }
        ]
    },
    {
        key: "post_wall_instagram",
        value: "Đăng bài lên trang cá nhân instagram",
        platform: "instagram",
        fields: [
            {
                label: "Folder sử dụng nick",
                type: "folderNick"
            },
            {
                label: "Số lượng nick",
                type: "numNick",
                defaultValue: 1
            },
            {
                type: "selectPostContent"
            }
        ]
    }
]

export const ConfigActionType = [
    "facebook", "instagram", "kakaotalk", "tiki"
]

export const Config = {
    statusAction: {
        0: {
            label: "Đang chờ",
            color: "processing"
        },
        1: {
            label: "Đang xử lý",
            color: "processing"
        },
        2: {
            label: "Thành công",
            color: "success"
        },
        3: {
            label: "Thất bại",
            color: "error"
        },
        4: {
            label: "Tạm dừng",
            color: "warning"
        }
    },
    statusTaskAction: {
        1: {
            label: "Đang xử lý",
            color: "processing"
        },
        2: {
            label: "Thành công",
            color: "success"
        },
        3: {
            label: "Tạm dừng",
            color: "warning"
        }
    },
    statusFormAction: {
        1: {
            label: "Hiển thị",
            color: "success"
        },
        2: {
            label: "Ẩn",
            color: "warning"
        }
    },
    typeFormAction: {
        public: {
            label: "Công khai",
            color: "success"
        },
        hidden: {
            label: "Không công khai",
            color: "hidden"
        },
    }
}

export const ListFieldsUid = [
    {
        value: "uid",
        label: "Uid"
    },
    {
        value: "username",
        label: "Username"
    },
    {
        value: "password",
        label: "Password"
    },
    {
        value: "avatar",
        label: "Avatar"
    },
    {
        value: "email",
        label: "Email"
    },
    {
        value: "password_email",
        label: "Password Email"
    },
    {
        value: "cookie",
        label: "Cookie"
    },
    {
        value: "access_token",
        label: "token"
    },
    {
        value: "user_agent",
        label: "User agent"
    },
    {
        value: "secret_2fa",
        label: "Secret 2fa"
    }, {
        value: "secret_2fa",
        label: "Link backup"
    }
    , {
        value: "proxy",
        label: "Proxy"
    }
    , {
        value: "device_id",
        label: "Device"
    },
    {
        value: "email_recover",
        label: "Email recover"
    },
    {
        value: "full_name",
        label: "Full name"
    },
    {
        value: "birthday",
        label: "Birthday"
    },
    {
        value: "gender",
        label: "Gender"
    },
    {
        value: "location",
        label: "Location"
    },
    {
        value: "relationship_status",
        label: "Relationship status"
    },
    {
        value: "friends_count",
        label: "Friends count"
    }
]

export const ListKeySystemData = [ "token_facebook", "proxy_facebook" ]

export const StatusSystemData = {
    1: {
        label: "Hoạt động",
        color: "success"
    },
    2: {
        label: "Không hoạt động",
        color: "danger"
    }
}

export const PackageNameUser = {
    "copy_post": {
        value: "copy_post",
        label: "Copy bài viết theo uid"
    }
}

export const StatusCopyPost = {
    1: {
        label: "Đang chạy",
        color: "success"
    },
    2: {
        label: "Tạm dừng",
        color: "warning"
    }
}

export const CopyPostTypes = {
    "profile_facebook": {
        value: "profile_facebook",
        label: "Profile facebook"
    }
}