import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select, Space, Tooltip, TreeSelect, message, notification } from "antd";
import React, { useEffect, useMemo, useState } from "react";

import WidgetFormItemPostContent from "./FormItemPostContent";
import classNames from "classnames";
import { API_URL } from "../../../../../lib/api";
import { fetchData } from "../../../../../lib/helpers";

const WidgetFormRender = (props) => {
    const { action, setDefaultValueForm, folderNick, folderContent } = props;

    const [checkBoxValue, setcheckBoxValue] = useState([]);
    const [ listFolderDriver, setlistFolderDriver ] = useState([]);

    const getListFolderDriver = async () => {
        try{
            message.loading({
                content: "Đang lấy dấy liệu...",
                key: "get_folder_driver"
            })

            let response = await fetchData({
                url: API_URL.googleapi.driverFiles,
                params: {
                    q: `'root' in parents`,
                    is_folder: true
                }
            })
            let data = (response.data).filter((item) => item.mimeType == "application/vnd.google-apps.folder");

            setlistFolderDriver([...data])

            message.success({
                content: "Lấy dữ liệu thành công",
                key: "get_folder_driver",
                duration: 3
            })

        } catch(err){
            message.error({
                content: err.message || "Đã có lỗi xảy ra",
                key: "get_folder_driver",
                duration: 3
            })
        } finally{
        }
    }

    const switchFields = (item) => {

        switch (item.type) {
            case "inputText":
                return <Input placeholder={item.placeholder} />
            case "inputNumber":
                return <InputNumber placeholder={item.placeholder} min={item.min}
                    addonBefore={item.addonBefore} className="w-100"
                />
            case "textarea":
                return <Input.TextArea placeholder={item.placeholder} rows={3} />
            case "select":
                return <Select placeholder={item.placeholder}
                    mode={item.mode}
                    maxTagCount={item.maxTagCount}
                >
                    {
                        item.values && item.values.map((item_) => {
                            return <Select.Option value={item_.value}>{item_.name}</Select.Option>
                        })
                    }
                </Select>
            case "checkbox":
                return <Checkbox.Group className="w-100" onChange={setcheckBoxValue}>
                    <Row className="w-100">
                        {
                            item.values && item.values.map((item_, i) => {
                                return <Col key={i} span={item.colSpan || 12}>
                                    <Checkbox value={item_.value}
                                        style={{
                                            lineHeight: "32px",
                                        }}
                                    >{item_.name}</Checkbox>
                                    {
                                        item_.childrenActive &&
                                        checkBoxValue.indexOf(item_.value) > -1 &&
                                        renderFields([
                                            {
                                                ...item_.childrenActive,
                                                className: "mb-2"
                                            }
                                        ])
                                    }
                                </Col>
                            })
                        }
                    </Row>
                </Checkbox.Group>
            case "radio":
                return <Radio.Group className="w-100">
                    <Row>
                        {
                            item.values && item.values.map((item_) => {
                                return <Col span={item.colSpan || 6}>
                                    {
                                        item_.tooltip ?
                                            <Tooltip title={item_.tooltip}>
                                                <Radio value={item_.value}>{item_.name}</Radio>
                                            </Tooltip>
                                            : <Radio value={item_.value}>{item_.name}</Radio>
                                    }
                                </Col>
                            })
                        }
                    </Row>
                </Radio.Group>
            // case "folderNick":
            //     return !props._ids && <TreeSelect
            //         dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            //         treeData={folderNick}
            //         placeholder="Hãy chọn một folder"
            //         treeDefaultExpandAll={false}
            //         multiple
            //     />
            // case "numNick":
            //     return <InputNumber min={1} className="mr-2" addonAfter={props._ids ? <>
            //         <span className="text-danger">/ {props._ids.length}</span>
            //     </> : false} />
            default:
                return
        }
    }
    const renderFields = (fields) => {
        try {
            fields = [...fields];
            return fields.map((item) => {
                switch (item.type) {
                    case "selectPostContent":
                        return <WidgetFormItemPostContent folderContent={folderContent} item={props.item} />
                    case "folderNick":
                        return
                    case "numNick":
                        return
                    case "googleDriver":
                        return <Form.Item label={item.label}
                            tooltip="Bạn phải kết nối với tài khoản Google để sử dụng tính năng này"
                        >
                            <div className="d-flex gap-2">
                                <Form.Item name="gg_driver_id" noStyle>
                                    <Select placeholder="Chọn folder"
                                    >
                                        {
                                            listFolderDriver.map((item) => {
                                                return <Select.Option value={item.id}>{item.name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Button className="ml-2"
                                    onClick={() => {
                                        getListFolderDriver()
                                    }}
                                >Lấy dữ liệu</Button>
                            </div>
                    </Form.Item>
                    default:
                        break
                }
                switch (item.formType) {
                    case "FormList":
                        return <Form.List
                            name={item.name}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <>
                                            <Form.Item
                                                label={item.label + ' ' + (index + 1)}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={["onChange", "onBlur"]}
                                                    className="mb-2"
                                                    rules={[
                                                        {
                                                            required: item.required,
                                                            message: "Không được để trống"
                                                        }
                                                    ]}
                                                >
                                                    {switchFields({...item, ...field})}
                                                </Form.Item>

                                                <Tooltip title="Xóa">
                                                    <MinusCircleOutlined
                                                        onClick={() => remove(field.name)}
                                                    />
                                                </Tooltip>
                                            </Form.Item>
                                        </>
                                    ))}
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                    >
                                        {item.labelButton || "Thêm mới"}
                                    </Button>

                                    <Form.ErrorList errors={errors} />
                                </>
                            )}
                        </Form.List>
                    case "FormGroup":
                        return <Form.Item
                            label={item.label}
                        >
                            <Input.Group compact className="row">
                                {renderFields(item.fields.map((field) => ({
                                    ...field,
                                    className: `col-${item.formCol || "12"} ${field.className}`
                                })))}
                            </Input.Group>
                        </Form.Item>
                    case "FormData": 
                        return <>
                            <p className="mb-2">
                                <label>{item.label}</label>
                            </p>
                            <div className="pl-4">
                                <Form.List 
                                    name={item.name}
                                >
                                {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field) => (
                                    <Space key={field.key} className="w-100">
                                        <Form.Item
                                            {...field}
                                            label="Field"
                                            name={[field.name, 'field']}
                                            rules={[{ required: true, message: 'Missing field' }]}
                                            style={{ width: 160 }}
                                            initialValue={"column1"}
                                            >
                                            <Select >
                                                {
                                                    [...new Array(10)].map((value, index) => {
                                                        return <Select.Option value={`column${index+1}`} >column {index+1}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                            </Form.Item>
                                        <Form.Item
                                        {...field}
                                        label="Value"
                                        name={[field.name, 'value']}
                                        >
                                        <Input />
                                        </Form.Item>
                        
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Space>
                                    ))}
                        
                                    <Form.Item>
                                    <Button onClick={() => add()} block icon={<PlusOutlined />}>
                                        Thêm dữ liệu
                                    </Button>
                                    </Form.Item>

                                    <Form.ErrorList errors={errors} />
                                </>
                                )}
                            </Form.List>   
                            </div>
                        </>             
                    default:
                        let rules = [{
                            required: item.required,
                            message: `${item.name} không được để trống`
                        }];

                        if (item.regex) {
                            rules.push({
                                pattern: new RegExp(item.regex.pattern, item.regex.options || "gm"),
                                message: item.regex.message || "Giá trị không hợp lệ #1"
                            })
                        }

                        return <Form.Item
                            name={item.name}
                            label={item.label}
                            rules={rules}
                            extra={item.extra}
                            tooltip={item.tooltip}
                            className={classNames( "mb-3", item.className)}
                            noStyle={item.noStyle}
                            initialValue={item.defaultValue}
                        >
                            {switchFields(item)}
                        </Form.Item>
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    const formItems = useMemo(() => {
        return action.fields && renderFields(action.fields)
    }, [action.fields, checkBoxValue, listFolderDriver])

    useEffect(() => {
        console.log("default value", action, props.item);
    }, [action])

    useEffect(() => {
        console.log("load ejject");
    }, [])

    return action.fields ? < React.Fragment>
        {formItems}
    </React.Fragment> : null
}

export default WidgetFormRender;